import { Component } from '@angular/core';
import { SvLogOut } from 'src/app/services-ws/sv-log-out';
import { PingAuthenticationService } from '@techops-ui/ping-authentication'; //pfLogout
import { CONST_local_url, CONST_nonProd_url, CONST_prod_url } from 'src/app/services-ws/sv-ws-constants'; //pfLogout

@Component({
  selector: 'sv-header-logout',
  templateUrl: './sv-header-logout.component.html'
})
export class SvHeaderLogoutComponent {

  //pfLogout constructor(private svLogOut: SvLogOut) { }
  constructor(private svLogOut: SvLogOut, private pingAuthService: PingAuthenticationService,) { }

  public logoutUser() {
    console.log('logout user');
    //pfLogout this.svLogOut.logout();
    
    //
    //pfLogout
    //
    const origin_url = window.location.origin;
    //console.log('Origin from window.location:', origin_url);
    var id_token = this.pingAuthService.User.id_token;
    //console.log('id_token', id_token);
    var logout_url = "";
    if(origin_url.includes(CONST_local_url) || origin_url.includes(CONST_nonProd_url)){
      logout_url = `https://pfloginapp-stage.cloud.aa.com/api/logout?id_token_hint=${id_token}&TargetResource=${origin_url}`;
    } else if(origin_url.includes(CONST_prod_url)){
      logout_url = `https://pfloginapp.cloud.aa.com/api/logout?id_token_hint=${id_token}&TargetResource=${origin_url}`;
    }
    //console.log('logout_url', logout_url);
    localStorage.clear();
    window.location.href = logout_url;
  }
}
