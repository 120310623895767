/* local instance */
// export const WS_BASE = 'http://localhost:4200';

 /* dev cloud akamai domain */
//export const WS_BASE = 'https://alpsv-dev.aa.com'; //TRKR828a

/* dev cloud URL */
// export const WS_BASE = 'https://aa-fxmr-dev-appservice-alpsv-east.azurewebsites.net';

/* dev ePaaS URL */
// export const WS_BASE = 'https://alpsv-dev.apps.depaas.qcorpaa.aa.com';

/* test ePaaS URL */
// export const WS_BASE = 'https://alpsv.apps.qcorpaa.aa.com';

/* for deployment with SpringBoot since this will be running on the same server */
 export const WS_BASE = '';

/* used to determine the title when routing/filtering */
export const APP_NAME = 'SV';
export const TITLE_SUFFIX = ' - ' + APP_NAME;

//
// To use common app-config file for all environments
//
export const CONST_local_url = 'localhost';
export const CONST_nonProd_url = 'alpsv-dev.aa.com';
export const CONST_prod_url = 'alpsv.aa.com';
